import styles from "@/styles/collection.module.scss";
import React, { Fragment } from "react";
import Slider from "react-slick";
import SwiperCore, { Navigation, Pagination } from "swiper";
import features7 from "../../../public/img/features-7.png";
import features8 from "../../../public/img/features-8.png";
import features9 from "../../../public/img/features-9.png";
import Image from "next/image";
import Link from "next/link";
import { homeLinks } from "@/data/home";

SwiperCore.use([Navigation, Pagination]);

const Collection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "180px",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "140px",
        },
      },
    ],
  };

  return (
    <Fragment>
      <section className={styles.collection}>
        <div className="container">
          <div className={styles.collectionheader}>
            <h3>{homeLinks.collection.title}</h3>
          </div>
          <fw-storyblock
            channel="senco"
            playlist="5zrla5"
            autoplay="true"
            branding="false"
          ></fw-storyblock>
          <Slider {...settings}>
            {homeLinks.collection.sliders.map((v: any, i) => (
              <Link href={v.link} key={i} passHref>
                <div className={styles.collection_slide_item}>
                  <div className={styles.slide_img}>
                    <Image
                      src={v.image}
                      className="img-fluid"
                      alt="features8"
                      width={460}
                      height={608}
                    />
                  </div>

                  <div className={styles.port_text}>
                    <div className={styles.h3_sub}>{v.name}</div>
                    <p>Collection</p>
                  </div>

                  <div className={styles.port_btn}>
                    <div className="btn-more ">
                      Explore <i className="bi bi-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
      </section>
    </Fragment>
  );
};

export default Collection;
